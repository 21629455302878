<template>
  <Modal
    id="modal-owner-check"
    :hideFooter="true"
    hide-header
    title=""
    no-close-on-backdrop
    size="lg"
  >
    <div class="mt-2">
      <p class="text-[#232D39] font-bold text-center text-2xl">
        {{ $t("RegisterPage.ExistingCustomer") }}
      </p>

      <p class="text-[#232D39] text-center">
        {{ $t("RegisterPage.ExistingCustomerInfo") }}
      </p>

      <b-form-group>
        <div class="flex justify-center gap-16">
          <b-form-radio v-model="existingCustomer" name="radio-1" :value="true">
            <span>{{ $t("RegisterPage.Yes") }}</span>
          </b-form-radio>
          <b-form-radio
            name="radio-1"
            v-model="existingCustomer"
            :value="false"
          >
            <span>{{ $t("RegisterPage.No") }}</span>
          </b-form-radio>
        </div>
      </b-form-group>

      <hr class="mt-3 mb-3" />

      <section v-if="existingCustomer">
        <b-form @submit.prevent="validationForm">
          <div class="text-[#232D39] font-bold text-center text-lg">
            {{ $t("RegisterPage.FillInfo") }}
          </div>
          <div class="text-[#232D39] text-center">
            {{ $t("RegisterPage.FillInfoContext") }}
          </div>
          
          <div class="flex flex-col items-center mt-[1.94rem]"> <!-- items-center will center align the children -->

            <!-- Organization Field -->
            <div class="w-full md:w-2/3 mb-2"> <!-- w-2/3 ensures it's two-thirds of the container on medium screens and above -->
                <b-form-group :label="$t('RegisterPage.Club')" label-class="text-center font-bold"> <!-- text-center will center the label -->
                  <p class="text-center">{{ $t("RegisterPage.chooseOrg") }}</p>
                    <v-select
                        v-model="selectedOrganization"
                        :options="organizations"
                        label="club_name"
                        @input="handleOrganizationInput"
                    ></v-select>
                </b-form-group>
            </div>

            <!-- Customer Number Field -->
            <div class="w-full md:w-1/3"> <!-- w-1/3 ensures it's one-third of the container on medium screens and above -->
                <p class="font-bold text-center">{{ $t("RegisterPage.CustomerName") }}</p> <!-- text-center will center the text -->
                <p class="text-center">{{ $t("RegisterPage.AsSeenFrom") }}</p> <!-- text-center will center the text -->
                <b-form-input v-model="form.customer_number" type="number" />
            </div>
          </div>


          <div class="mt-8 flex justify-center mb-8">
            <button
              type="submit"
              class="flex w-1/4 p-1 text-center focus:outline-none bg-[#16149F] text-white whitespace-nowrap rounded-md"
            >
              <span class="w-full flex justify-center" v-if="submitting">
                <b-spinner small type="grow" />
                {{ $t("Message.loading") }}...
              </span>

              <span
                class="whitespace-nowrap text-center w-full text-base"
                v-else
                v-text="$t('RegisterPage.Proceed')"
              />
            </button>
          </div>
        </b-form>
      </section>
      <div class="mt-8 flex justify-center mb-8" v-else>
        <button
          @click="handleNoAction"
          class="flex w-1/4 p-1 text-center focus:outline-none bg-[#16149F] text-white whitespace-nowrap rounded-md"
        >
          <span class="w-full flex justify-center" v-if="submitting">
            <b-spinner small type="grow" />
            {{ $t("Message.loading") }}...
          </span>

          <span
            class="whitespace-nowrap text-center w-full text-base"
            v-else
            v-text="$t('RegisterPage.Proceed')"
          />
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

export default {
  components: {
    vSelect
  },
  props: {
    toggleSignupExtension: {
      type: Function,
      required: false,
    },
    toggleChooseVersion: {
      type: Function,
      required: false,
    },
    setExistingUserData: {
      type: Function,
      required: false,
    },
    setToApiVersion: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      submitting: false,
      existingCustomer: false,
      showModal: true,
      form: {
        customer_number: "",
        organization_uuid: "",
      },
      organizations: [],
      searchTimeout: null, // to store the timer
      selectedOrganization: null,
      searchQuery: ''
    };
  },
  methods: {
    fetchAllOrganizations() {
        const initialUrl = '/organizations/';
        this.fetchOrganizationsRecursively(initialUrl)
            .catch(error => {
                console.error("Error fetching organizations:", error);
            });
    },
    fetchOrganizationsRecursively(url) {
        return this.$useJwt.getOrganizations({}, url).then(response => {
            this.organizations = this.organizations.concat(response.data.results);
            if (response.data.next) {
                return this.fetchOrganizationsRecursively(response.data.next);
            } else {
                return this.organizations;
            }
        });
    },
    handleOrganizationInput(value) {
      if (value && value.uuid) {
        this.form.organization_uuid = value.uuid;
      } else {
        this.form.organization_uuid = "";
        this.selectedOrganization = null;
      }
    },
    validationForm() {
      if (!this.selectedOrganization) {
        this.popup(
          this.$t("RegisterPage.organizationMandatory"),
          "danger",
          this.$t("Message.Failed"),
          "AlertTriangleIcon"
        );
        return false;
      } else if (this.form.customer_number === "") {
        this.popup(
          this.$t("RegisterPage.CustomerNumberRequired"),
          "danger",
          this.$t("Message.Failed"),
          "AlertTriangleIcon"
        );
        return false;
      } else {
        this.submitting = true;
        this.$useJwt
          .checkExistingUser(this.form)
          .then((res) => {
            if (res.status === 200) {
              const emptyData = this.isObjectNotEmpty(res?.data?.data);

              if (emptyData) {
                this.submitting = false;
                this.setExistingUserData(res?.data);
                this.toggleSignupExtension();
                
                // Users can NOT choose the file version if their contract expires within 30 days
                // Parse the date from the response data
                const oldContractEndDateStr = res?.data?.data?.old_contract_end_date;
                const oldContractEndDate = oldContractEndDateStr ? new Date(oldContractEndDateStr) : null;
                // Check if the date is valid
                if (oldContractEndDate instanceof Date && !isNaN(oldContractEndDate)) {
                  this.toggleChooseVersion();
                }
              }
            } else {
              this.submitting = false;
              this.popup(
                res?.message,
                "danger",
                this.$t("Message.Failed"),
                "AlertTriangleIcon"
              );
            }
          })
          .catch((err) => {
            this.submitting = false;
            this.popup(
              err.response.data.message,
              "danger",
              this.$t("Message.Failed"),
              "AlertTriangleIcon"
            );
          });
      }
    },

    handleNoAction() {
      this.setToApiVersion();
      this.toggleSignupExtension();
    },

    isObjectNotEmpty(obj) {
      if (obj && typeof obj === "object" && Object.keys(obj)?.length > 0) {
        return true;
      }
      return false;
    },

    popup(message, variant, title, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          variant: variant,
          text: message,
        },
      });
    },

    validateGolfigoNumber(number) {
      if (!number) {
        return false;
      }
      let num = number.split("-");
      if (num.length > 2) {
        return false;
      }
      if (num[0].length > 6 || num[0].length < 6) {
        return false;
      }
      if (num[1].length > 4 || num[1].length < 4) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.$bvModal.show("modal-owner-check");
  },
  created() {
    this.fetchAllOrganizations(); // Fetch all organizations initially immediately
  },
};
</script>
